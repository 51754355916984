<template>
  <div v-if="!isLoading" class="page-wrapper">
    <div class="page-header">
      <div class="container">
        <div class="page-header-inner">
          <button class="button button--rounded" @click="$router.back()">
            <i class="ri-arrow-left-s-line"></i>Back
          </button>
          <div class="invoice__wrapper">
            <div class="invoice__main">
              <div class="invoice__date-wrapper">
                <div class="invoice__date">
                  <i class="ri-calendar-fill"></i>
                  {{ moment(invoice.dateFrom).format('MMM DD') }} – {{ moment(invoice.dateTill).format('MMM DD') }}
                </div>
              </div>
              <div class="invoice__name">
                {{ invoice.billingName }}
              </div>
              <button class="icon-button icon-button--round">
                <a :href="invoice.invoicePdfURL" target="_blank"><i class="ri-arrow-down-circle-fill"></i></a>
              </button>
              <button
                class="button button--fill button--rounded"
                :class="{'button--green': invoice.status === 'paid'}"
                :disabled="invoice.status === 'paid'"
                @click="mark"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="container">
        <div class="orders__wrapper">
          <Order v-for="order of invoice.deliveryOrders" :key="order.id" :order="order" />
        </div>
      </div>
    </div>
    <!-- <div class="page-main">
      <div class="order__wrapper">
        <div class="order__part">
          <div class="order__customer">
            <div class="order__customer-icon">
              <div class="icon-button icon-button--round icon-button--blue">
                <i class="ri-arrow-up-circle-line"></i>
              </div>
            </div>
            <div class="order__customer-name">{{ order.pickUpName }}</div>
            <div class="order__customer-address">Address: {{ order.pickUpAddress }}</div>
          </div>
          <div class="order__customer">
            <div class="order__customer-icon">
              <div class="icon-button icon-button--round icon-button--orange">
                <i class="ri-arrow-down-circle-line"></i>
              </div>
            </div>
            <div class="order__customer-name">{{ order.dropOffName }}</div>
            <div class="order__customer-address">Address: {{ order.dropOffAddress }}</div>
          </div>
        </div>
        <div class="order__part">
          <div class="order__row">
            <div class="order__row-title">Date of delivery</div>
            <div class="order__row-subtitle">{{ moment.utc(order.routeDate).format('DD MMM YYYY') }}</div>
          </div>
          <div v-if="order.notes" class="order__row">
            <div class="order__row-title">Notes</div>
            <div class="order__row-subtitle">{{ order.notes }}</div>
          </div>
          <div v-if="order.attachmentPath" class="order__row">
            <div class="order__row-title">Attachment</div>
            <el-image :src="order.attachmentPath" :preview-src-list="[order.attachmentPath]" fit="cover">
              <div slot="error" class="order__image-error" />
            </el-image>
          </div>
        </div>
        <div v-if="(order.status === 6 && mePay) || (order.status === 5 && mePay)" class="order__part order__buttons">
          <button class="button button--green order__button" @click="pay">Pay</button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import Order from '../Order'

export default {
  name: 'EnterpriseInvoisePage',
  components: {Order},
  data() {
    return {
      moment,
      // mePay: false,
      // fileUrl: null,
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      invoice: s => s.invoices.invoice
    })
    // pathID() {
    //   return this.$route.params.id
    // }
  },
  // watch: {
  //   async pathID(id) {
  //     this.isLoading = true
  //     await this.fetchOrder(id)
  //     this.isLoading = false
  //   }
  // },
  async created() {
    const invoiceID = this.$route.params.id
    if (invoiceID != this.invoice?.id) {
      await this.fetch(invoiceID)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      fetch: 'invoices/fetchInvoice',
      markPayed: 'invoices/confirm'
    }),
    async mark() {
      const resp = await this.markPayed(this.invoice.id)
      if (resp.data.success) {
        this.invoice.status = 'paid'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
@import 'src/assets/styles/_variables.scss';

.order__image-error {
  background-color: $grey_border_light;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
